import { navigate } from "gatsby-link"
import React, { useEffect } from "react"

const PressDetailsPage = () => {
  useEffect(() => {
    navigate("/press")
  }, [])

  return <></>
}

export default PressDetailsPage
